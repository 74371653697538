const about = [
    {
        header: "Hi!",
        description: "My name is Tanushsrisai Nadimpalli or simply Tanush. I am curently a freshman at the University of Minnesota: Twin Cities and I am studying Computer science.\nI am experienced in HTML, CSS, Javascript, Python, React and Java. I have also worked with technologies such as Firebase, Node.js, Google Cloud and others. I have experience in front-end development, back-end development and even a little bit of game development."
    },
    

   
]


export default about;